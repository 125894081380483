import React from "react";
import Layout from "../components/layout";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";
import StandardHeader from "../assets/elements/StandardHeader";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import ThreeColumnFeatureSection from "../assets/elements/ThreeColumnFeatureSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import Cta from "../partials/Cta";
import {
  BeakerIcon,
  ChartPieIcon,
  ChatIcon,
  CubeIcon,
  DeviceMobileIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";

import SvgDataEngine from "../assets/images/data-engine-cropped.svg";
import SEO from "../components/seo";
import CustomLink from "../components/CustomLink";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Synthetic-aperture radar",
    description: "All SAR modes - in one platform.",
    bullets: [
      "Spot, strip, and scan imaging",
      "Efficient chipping capabilities",
      "Metadata handling",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/platform-sar-raw.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Synthetic-aperture radar"}
      />
    ),
  },
  {
    name: "Geospatial",
    description: "Seamless support for both satellite and aerial imaging.",
    bullets: [
      "Geospatial synchronisation",
      "Hyper-spectral images",
      "Specialised layers",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/platform-geospatial.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Geospatial"}
      />
    ),
  },
  {
    name: "Thermal",
    description: "Integrate all your sensor data in one tool.",
    bullets: [
      "Infrared, night vision, and thermal mapping",
      "Model training & inference",
      "Advanced edge deployment",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/platform-thermal.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Thermal"}
      />
    ),
  },
  {
    name: "Aerial video footage",
    description: "Derive actionable insights from video streams.",
    bullets: [
      "Advanced video processing",
      "Flexible deployment options",
      "Multiple object tracking",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/platform-aerial-desert.webp"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Aerial video footage"}
      />
    ),
  },
  {
    name: "Reconnaissance & surveillance",
    description: "Gain fast & accurate insights from satellite imagery.",
    bullets: [
      "Auto-detect & track visual objects of interest",
      "Advanced real time analytics",
      "100% data-secure for sensitive applications",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/Survelliance.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Reconnaissance & surveillance"}
      />
    ),
  },
  {
    name: "Disaster response",
    description: "Process critical visual data in real time.",
    bullets: [
      "Enable & augment rescue efforts",
      "Sensor fusion for full situational awareness",
      "Integration into the response ecosystem",
    ],
    image: (
      <StaticImage
        loading="lazy"
        src={"../assets/images/Disaster Response.png"}
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Disaster response"}
      />
    ),
  },
];

const featuresGrid = [
  {
    name: "Flexible tools",
    description:
      "Our software support a wide variety of data modalities (e.g. RGB, synthetic-aperture radar, geospatial, and thermal).",
    icon: CubeIcon,
  },
  {
    name: "Handle complex use cases",
    description:
      "Create arbitrarily rich nested labeling structures accommodating all label modalities in one place.",
    icon: BeakerIcon,
  },
  {
    name: "On-premises annotation, labeling and segmentation",
    description:
      "Organise and scale your analyst teams easily and quickly with our collaboration features.",
    icon: ChatIcon,
  },
  {
    name: "Model training & inference",
    description:
      "Use our micro-models to detect, classify, and segment within hours instead of days. Use active learning for continual model improvement.",
    icon: LightningBoltIcon,
  },
  {
    name: "Deploy on edge",
    description:
      "Use models in diverse environments with our ready-made and easy to use deployment tools.",
    icon: DeviceMobileIcon,
  },
  {
    name: "Analyse",
    description:
      "Evaluate your labels with high granularity to get precise estimations of your label quality and model performance.",
    icon: ChartPieIcon,
  },
];

export default function Government({ location }) {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        title={
          "Secure, automated annotation tools for Aerospace & Defense partners"
        }
        description={
          "Encord's solution is a natural fit for sensitive use cases where security and privacy surrounding data is paramount."
        }
      />

      {/* Use cases */}
      <ThreeColumnUseCaseSection
        caseStudy={null}
        title={"Use cases"}
        header={
          "Comprehensive automated data labeling for defense, and aerospace"
        }
        description={
          <>
            Encord's robust platform handles numerous computer vision data
            modalities including aerial imaging, synthetic aperture radar data,
            and thermal imaging all in an integrated fashion.{" "}
            <CustomLink
              to={"/sar/"}
              className="text-gray-700 hover:text-gray-900 transition duration-150 ease-in-out"
            >
              Learn more
            </CustomLink>
            .
          </>
        }
        useCases={useCases}
      />

      {/* Three column feature section */}
      <ThreeColumnFeatureSection
        title={"How it works"}
        header={"Unlock air-gapped AI development capabilities"}
        description={
          "Our on-premise solution allow government partners to capture the efficiency gains of our micro-model technology while retaining 100% control of their data."
        }
        features={featuresGrid}
      />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={"Invest"}
        header={"Our investors 2"}
        description={"Our investors"}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/platform-sar-model.png"}
            width={850}
            alt="Sar model"
          />
        }
        // Looks better in StaticImage than SVG Import
        imageTwo={<img src="https://encord.cdn.prismic.io/encord/9ba0f87b-f7f9-4135-8fba-20c76473d6f6_data-engine-cropped.svg" loading="lazy" className="w-full h-auto" width={0} height={0} alt="Data engine" />}
        excludeImageTwoShade={true}
        headerOne={"Create continuous model deployment pipelines"}
        headerTwo={"Centralise your computer vision data operations"}
        descriptionOne={
          "Train, test, and deploy models in automated pipelines. Ensure continous model improvement for achieving AI objectives with active learning techniques."
        }
        descriptionTwo={
          "One scalable platform for all of your data. Manage data, labels, models with our graphical interface, APIs, and Python SDK."
        }
      />

      <Cta
        location={location}
        pageName={"Government Page"}
        ctaText={"Accelerate AI model training with Encord"}
      />
    </Layout>
  );
}

export const Head = () => (
  <SEO
    title="On-Prem Model Training for Computer Vision in Government | Encord"
    description="Encord is a secure active learning platform for computer vision, purpose-built for government use-cases. Maintain complete control of sensitive data."
  >
    <meta name="robots" content="noindex, nofollow" />
  </SEO>
);
